<template>
  <div class="inputFormGroup">
    <label :for="id">{{ name }}</label>
    <input :type="type" :value="value" :id="id" @input="handleInput" />
  </div>
</template>
<script>
import { toRefs, computed } from "vue"
export default {
  props: ["value", "name", "type"],
  setup(props, { emit }) {
    const { name } = toRefs(props)
    const id = computed(() => {
      return name.value && name.value.toLowerCase().replace(/\W/, "_")
    })
    const handleInput = e => {
      emit("update:value", e.target.value)
    }

    return { id, handleInput }
  }
}
</script>
<style lang="scss" scoped>
.inputFormGroup {
  display: inline-flex;
  flex-direction: column;
}
label {
  color: $accent;
  font-weight: bold;
}
input {
  text-align: center;
}
</style>
