<template>
  <div>
    <sentAuthors v-bind:sent_authors="sent_authors"> </sentAuthors>
    <h1>Send Out Author Emails</h1>
    <form>
      <InputFormGroup v-model:value="user_issue" name="Issue Name" />
      <InputFormGroup v-model:value="issue_release" name="Issue Release" />
      <InputFormGroup v-model:value="deadline" name="Deadline" />
      <br />
      <InputFormGroup
        v-model:value="upcoming"
        name="Upcoming"
        type="checkbox"
      />
    </form>
    <h4>Airtable API Key:</h4>
    <form v-on:submit.prevent="fetchAuthors">
      <input v-model="apikey" />
      <button type="submit">
        {{ searching ? "Searching" : "Fetch Records" }}
      </button>
    </form>
    <component
      v-if="authors.length === 0"
      v-bind:author="example_author"
      v-bind:issue_name="issue_name"
      v-bind:issue_release="issue_release"
      v-bind:deadline="deadline"
      v-bind:count="count"
      v-bind:upcoming="upcoming"
      v-bind:editor="editor"
      ref="preview"
      :is="mailTemplate"
    />
    <div v-if="error">Error: {{ error }}</div>
    <div v-if="authors.length" class="results">
      <div
        v-for="(author, i) in authors"
        class="authorList" a
        v-bind:key="author.id"
      >
        <h2 @click="scrollNext">{{ author.name }}</h2>
        <component
          v-bind:scroll="scrollNext"
          :ref="
            el => {
              previews[i] = el
            }
          "
          @new-author="addNewAuthor"
          v-bind:active="false"
          class="preview"
          v-bind:issue_name="issue_name"
          v-bind:issue_release="issue_release"
          v-bind:upcoming="upcoming"
          v-bind:deadline="deadline"
          v-bind:count="articles"
          v-bind:author="author"
          v-bind:editor="editor"
          v-bind:is="mailTemplate"
          v-bind:id="i"
        >
        </component>
      </div>
    </div>
  </div>
</template>

<script>
//external scripts
import { ref, onBeforeUpdate, computed, onMounted, watch } from "vue"
import { gsap } from "gsap"
import { TimelineLite, ScrollToPlugin } from "gsap/all"
gsap.registerPlugin(ScrollToPlugin)

// components
import Preview from "@/mail_templates/PreviewLinks"
import Publish from "@/mail_templates/PublishedLinks"
import sentAuthors from "@/components/SentAuthors"
import InputFormGroup from "@/components/InputFormGroup"

// internal scripts
import handleStoreData from "@/composables/handleStoreData"

export default {
  components: {
    sentAuthors,
    InputFormGroup
  },
  setup() {

    const { 
      keys, 
      storeData, 
      computedData,
      fetchAuthors
    } = handleStoreData();

    // Handle preview scrolls (separate)
    // Refs
    const previews = ref([])

    // Methods
    const scrollNext = (current)=>{
      if (!previews.value) return

      const tl = new TimelineLite()
      const next = previews.value[current + 1];

      tl.to(window, 0.2, {
        scrollTo: {
          y: next ? next.$el : 0,
          offsetY: 100
        }
      })
    }

    // make sure to reset the refs before each update
    onBeforeUpdate(() => {
      previews.value = []
    })

    //setup local storage watch 
    const localkeys = ["apikey", "issue_release", "deadline", "user_issue"]
    localkeys.forEach((k)=>{
      watch(keys[k], (value) => {
        localStorage[k] = value
      })
    })

    //init local storage
    onMounted(()=>{
      for (const key of localkeys) {
        let localvalue = localStorage[key];
        // set localstorage key to 
        if(localvalue) keys[key].value = localvalue;
      }
      if(!keys.user_issue.value) keys.user_issue.value = keys.issue_default_name
    })

    // dynamic templating
    const mailTemplate = computed(()=>{
      if (!keys.deadline.value && !keys.upcoming.value) return Publish;
      return Preview;
    })

    //track authors sent
    const addNewAuthor = (email)=>{
      const updateSent = keys.sent_authors.value
      updateSent.push(email)
      keys.sent_authors.value = updateSent
    }

    

    return {
      ...keys,
      ...storeData,
      ...computedData,
      fetchAuthors,
      previews,
      scrollNext,
      mailTemplate,
      addNewAuthor
    }
  }
}
</script>

<style lang="scss" scoped>
form {
  input,
  button {
    padding: 0.5em 1em;
    border: 1px solid black;
  }
}
button {
  background: $accent;
  color: #fff;
  &:hover {
    background: black;
    cursor: pointer;
  }
}
</style>
