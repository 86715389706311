import { ref, watch, reactive, computed } from "vue"

export default function useTemplateSetup(
  authorObj,
  editorObj,
  issueObj,
  attrs,
  scroll,
  emit
) {
  // Define by value for cleaner reading
  const editor = editorObj.value
  const author = authorObj.value
  const issue_name = issueObj.value

  //Define refs
  const message = ref(null)
  const editorTag = computed(() => {
    return `${editor.name} (${editor.title}) <${editor.email}>`
  })
  const status = reactive({
    sent: false,
    sending: false,
    error: false
  })

  watch(
    () => status.sent,
    () => {
      if (status.sent !== true) return
      scroll.value(attrs.id)
      emit("new-author", author.email)
    }
  )

  const sendTransactionalMail = async subject => {
    // Initialize transaction data
    status.sending = true
    status.error = false
    const email = {
      from: editorTag.value,
      to: author.email,
      bcc: editor.email,
      subject: subject,
      html: message.value.innerHTML
    }
    const testEmail = "dagneyandleo@gmail.com"
    const testData = {
      from: editorTag.value,
      to: testEmail,
      bcc: testEmail,
      subject: subject,
      html: message.value.innerHTML
    }
    const data = JSON.stringify(email)
    const handleError = async response => {
      if (response.ok) return
      status.sending = false
      status.error = true
      throw new Error(response)
    }

    const fetchAsync = async () => {
      try {
        const response = await fetch("/.netlify/functions/mailgun", {
          method: "post",
          body: data
        })
        await handleError(response)
        const mailReceipt = await response.json()

        // toggle sent status
        status.sent = true
        status.sending = false
        status.error = false

        // console.log("Log", mailReceipt);
      } catch (e) {
        console.error("Error", e)
        return e
      }
    }
    await fetchAsync()
  }

  const removeStaging = url => {
    if (!url) return
    const regex = /(?!http.*\/\/)(staging\.)(?=emerge-writerscolony.org\/.*\/)/gm
    const subdomain = ""
    return url.replace(regex, subdomain)
  }

  const subject = computed(() => {
    return `The <span class="highlight alternate">${issue_name}</span> Edition
    of eMerge is live!`
  })

  const mailMethods = {
    sendTransactionalMail,
    removeStaging
  }

  return {
    status,
    message,
    editorTag,
    subject,
    mailMethods
  }
}
