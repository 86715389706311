import { computed, reactive, ref, watch } from "vue"
import { useStore } from "vuex"

export default function handleStoreData() {
  const store = useStore()
  const keys = {
    apikey: ref(""),
    issue_release: ref("ISSUE_RELEASE"),
    issue_default_name: "OVERRIDE_ISSUE_NAME",
    user_issue: ref(""),
    deadline: ref("DEADLINE"),
    count: ref(50),
    example_author: reactive({
      name: "AUTHOR_NAME",
      email: "",
      is_new: false,
      article_names: "AN_ARTICLE_NAME",
      preview_links: "LINK_TO_THE_PREVIEW",
      published_links: "LINK_TO_THE_ARTICLE",
      slug: "LINK_TO_AUTHOR_PAGE"
    }),
    sent_authors: ref([]),
    upcoming: ref(false)
  }

  const storeData = {
    authors: computed(() => store.state.authors),
    searching: computed(() => store.state.searching),
    error: computed(() => store.state.error),
    articles: computed(() => store.state.articles),
    editor: computed(() => store.state.editor)
  }

  const computedData = {
    issue_name: computed(() => {
      const exceptions = [keys.issue_default_name, ""]
      const isDefaultName = exceptions.indexOf(keys.user_issue.value) >= 0
      return isDefaultName ? store.state.issue_name : keys.user_issue.value
    })
  }
  const fetchAuthors = () => {
    store.dispatch("FETCH_AUTHORS", keys.apikey.value)
  }

  return {
    keys,
    storeData,
    computedData,
    fetchAuthors
  }
}
