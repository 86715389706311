<template>
  <div v-if="author" class="template_preview">
    <h3>
      Subject: You're Being Featured in the
      <span class="highlight alternate">{{ issue_name }}</span> Edition of
      eMerge!
    </h3>
    <h3 v-if="author.email">
      Email: <span class="highlight">{{ author.email }}</span>
    </h3>
    <h3 v-if="editor.email">
      From: <span class="highlight">{{ editorTag }}</span>
    </h3>
    <h3 v-if="author.is_new">New Author</h3>
    <hr />
    <div class="message" ref="message">
      <p>
        Dear <span class="highlight">{{ author.name }}</span
        >,
      </p>
      <div v-if="upcoming">
        <p>
          We would be honored to include one or more of your submissions in an
          upcoming issue of eMerge! Thank you for sharing your work with us.
          Despite a high volume of submissions this year, we took our time
          reading and selecting each piece, and we appreciate your patience in
          this process.
        </p>
        <p>
          Due to the incredibly high quality of submissions we've received,
          we've decided to split this edition of eMerge into multiple releases
          to give each submission the focus it deserves.
        </p>
      </div>
      <p>
        One or more of your submissions will be included in the
        <span class="highlight alternate">{{ issue_name }}</span> edition of
        eMerge.
      </p>
      <div v-if="deadline">
        <p>
          We've included preview links to your submissions below for you to
          review before it goes live. This preview is for editing purposes only
          - It will not look the same on the live site.
          <b v-if="deadline"
            >The deadline for revisions is
            <span class="highlight alternate">{{ deadline }}</span
            >.</b
          >
          <span v-if="issue_release">
            The issue will go live
            <span class="highlight alternate">{{ issue_release }}</span
            >.
          </span>
        </p>
        <p>
          Your bio can be found by clicking on your name underneath the article
          title in the preview link. Some links to websites asoociated with you
          can be found below your bio in markdown format.
        </p>
        <p>
          <b>Note for new authors:</b> Your bios may not be available for
          preview until publication. This is an unfortunate limitation in our
          publishing system, as new authors pages often aren't created until
          they have an article already published. If you have corrections post
          publication, we will get to them swiftly.
        </p>
        <p>The password to access the preview is '<b>emergeauthors</b>'.</p>

        <h4>What We Are Looking For</h4>
        <ul>
          <li>Spelling Mistakes</li>
          <li>Small formatting mistakes (bold, italic, links, etc)</li>
          <li>
            Small edits ("Oh, I meant she instead of he in the third paragraph")
          </li>
          <li>Bio edits or rewrites that are less than 500 characters</li>
        </ul>
        <h4>What We Are NOT Looking For</h4>
        <ul>
          <li>Submission rewrites</li>
          <li>
            Big formatting changes (ie. "I need this paragraph centered, then an
            exactly 2in indent on line 20") - This is an online publication that
            needs to work on multiple devices, so formatting is limited.
          </li>
          <li>Photo changes</li>
        </ul>
      </div>
      <h4>
        {{ deadline ? "Preview Links" : "Accepted Articles" }} for
        <span class="highlight">{{ author.name }}</span>
      </h4>
      <table style="border:3px solid black;border-spacing:10px 5px">
        <th></th>
        <tr>
          <td>
            <span class="highlight" v-html="author.article_names"></span>
          </td>
          <td v-if="deadline">
            <span class="highlight" v-html="author.preview_links"></span>
          </td>
        </tr>
      </table>

      <p v-if="!upcoming">
        <b
          >Deadline for edits is
          <span class="highlight alternate">{{ deadline }}</span
          >. Changes submitted after the deadline will not be accepted.</b
        >
      </p>
      <p>
        Thank you for all your wonderful contributions! <br />{{ editor.name }}
        <br />{{ editor.title }}
      </p>
    </div>
    <button
      :disabled="status.sent"
      v-if="author.email"
      @click="
        sendTransactionalMail(
          `You're Being Featured in the` +
            issue_name +
            ` Edition of
      eMerge!`
        )
      "
      class="submit"
    >
      {{
        status.sending
          ? "Sending..."
          : status.error
          ? "Eek!"
          : status.sent
          ? "Sent!"
          : "Send Email"
      }}
    </button>
  </div>
</template>
<script>
import { props as templateProps } from "@/mail_templates/templateprops"
import { toRefs } from "vue"
import useTemplateSetup from "@/composables/useTemplateSetup"

export default {
  props: templateProps,
  setup(props, { emit, attrs }) {
    // setup refs
    const { author, editor, issue_name, scroll } = toRefs(props)

    // setup methods
    const {
      mailMethods,
      editorTag,
      status,
      subject,
      message
    } = useTemplateSetup(author, editor, issue_name, attrs, scroll, emit)

    // expose data
    return {
      message,
      status,
      editorTag,
      subject,
      ...mailMethods
    }
  }
}
</script>
<style lang="scss" src="@/mail_templates/template.scss" scoped></style>
