import { createStore } from "vuex";
import Airtable from 'airtable';

import { default as editor } from "../assets/editor.json";

export default createStore({
  state: {
    authors: [],
    searching: false,
    error: '',
    articles: 0,
    issue_name: '',
    editor: editor || {}
  },
  mutations: {
    authors(state, authors) {
      state.authors = authors
    },
    searching(state, bool) {
      state.searching = bool
    },
    articles(state, number) {
      state.articles = number
    },
    issue_name(state, string) {
      state.issue_name = string
    },
    clearAuthors(state) {
      state.authors = []
      state.articles = 0
      state.error = ''
      state.issue_name = ''
    },
    error(state, errorMessage) {
      state.error = errorMessage
    }
  },
  actions: {
    FETCH_AUTHORS: (context, apikey) => {
      context.commit('searching', true)
      context.commit('clearAuthors')
      context.searching = true

      if (!apikey) {
        context.commit('error', 'An API key is required to connect to Airtable')
        context.commit('searching', false)
        return
      }

      const base = new Airtable({ apiKey: apikey }).base('appmDuK8HITJmlGNP')
      const newAuthors = []
      base('Authors')
        .select({
          // Selecting the first 3 records in Grid view:
          maxRecords: 60,
          view: 'Mail Merge Custom'
        })
        .eachPage(
          function page(records, fetchNextPage) {
            // This function (`page`) will get called for each page of records.

            records.forEach(function(record) {
              if (record.get('Name')) {
                console.log('Retrieved', record.get('Name'))
                newAuthors.push({
                  id: record.get('id'),
                  name: record.get('Name'),
                  email: record.get('Email'),
                  is_new: record.get('Is New'),
                  article_names: record.get('Article Names'),
                  published_links: record.get('Published Links'),
                  preview_links: record.get('Preview Links'),
                  biography_url: record.get('Emerge Bio URL')
                })
              }
            })

            // To fetch the next page of records, call `fetchNextPage`.
            // If there are more records, `page` will get called again.
            // If there are no more records, `done` will get called.
            fetchNextPage()
          },
          function done(err) {
            // Grab Article count from current issue
            let articleCount = 0
            let issueName = 'next issue'
            base('Issues')
              .select({
                // Selecting the first 3 records in Grid view:
                maxRecords: 10,
                view: 'Grid view',
                filterByFormula: '{Is Current}'
              })
              .eachPage(
                function page(records, fetchNextPage) {
                  // This function (`page`) will get called for each page of records.

                  records.forEach(function(record) {
                    console.log(
                      'Article Count',
                      record.get('Article Count (#)')
                    )
                    articleCount += record.get('Article Count (#)')
                    issueName = record.get('Name')
                    console.log(issueName)
                  })

                  // To fetch the next page of records, call `fetchNextPage`.
                  // If there are more records, `page` will get called again.
                  // If there are no more records, `done` will get called.
                  fetchNextPage()
                },
                function done(err2) {
                  if (err) {
                    console.error(err)
                    context.commit('error', err.message)
                  } else if (err2) {
                    console.error(err)
                    return
                  } else {
                    context.commit('authors', newAuthors)
                    context.commit('articles', articleCount)
                    context.commit('issue_name', issueName)
                  }
                  context.commit('searching', false)
                }
              )
          }
        )
    }
  }
});
