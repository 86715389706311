<template>
  <div v-if="author" class="template_preview">
    <h3>
      Subject:
      <span class="subject"
        >The <span class="highlight alternate">{{ issue_name }}</span> Edition
        of eMerge is live!</span
      >
    </h3>
    <h3 v-if="author.email">
      Email: <span class="highlight">{{ author.email }}</span>
    </h3>
    <h3 v-if="editor.email">
      From: <span class="highlight">{{ editorTag }}</span>
    </h3>
    <hr />
    <div class="message" ref="message">
      <p>
        Dear <span class="highlight">{{ author.name }}</span
        >,
      </p>
      <p>
        The new issue is live now on
        <a href="https://emerge-writerscolony.org">the eMerge website</a>!
      </p>
      <div>
        <p>
          We've included links to your articles below as well as your biography.
          If you have any questions or issues let us know.
        </p>
      </div>
      <h4>
        Published Articles for
        <span class="highlight">{{ author.name }}</span>
      </h4>
      <table style="border:3px solid black;border-spacing:10px 5px">
        <th></th>
        <tr>
          <td>
            <span class="highlight" v-html="author.article_names"></span>
          </td>
          <td>
            <span
              class="highlight"
              v-html="removeStaging(author.published_links)"
            ></span>
          </td>
        </tr>
      </table>

      <h4>Direct Link to Biography</h4>
      <p>
        <a v-bind:href="removeStaging(author.biography_url)">{{
          removeStaging(author.biography_url)
        }}</a>
      </p>
      <p>
        Thank you for all your wonderful contributions! <br />{{ editor.name }}
        <br />{{ editor.title }}
      </p>
    </div>
    <button
      :disabled="status.sent"
      v-if="author.email"
      @click="
        sendTransactionalMail(
          'The ' + issue_name + ' Edition of eMerge is live!'
        )
      "
      class="submit"
    >
      {{
        status.sending
          ? "Sending..."
          : status.error
          ? "Eek!"
          : status.sent
          ? "Sent!"
          : "Send Email"
      }}
    </button>
  </div>
</template>
<script>
import { props as templateProps } from "@/mail_templates/templateprops"
import { toRefs } from "vue"
import useTemplateSetup from "@/composables/useTemplateSetup"

export default {
  props: templateProps,
  setup(props, { emit, attrs }) {
    // setup refs
    const { author, editor, issue_name, scroll } = toRefs(props)

    // setup methods
    const {
      mailMethods,
      editorTag,
      subject,
      status,
      message
    } = useTemplateSetup(author, editor, issue_name, attrs, scroll, emit)

    // expose data
    return {
      message,
      status,
      editorTag,
      subject,
      ...mailMethods
    }
  }
}
</script>
<style lang="scss" src="@/mail_templates/template.scss" scoped></style>
