<template>
  <div id="app">
    <img alt="Vue logo" src="./assets/logo.svg" />
    <authorsList />
  </div>
</template>

<script>
import authorsList from "./components/authorsList.vue"

export default {
  name: "app",
  components: {
    authorsList
  }
}
</script>

<style lang="scss">
body {
  background: #292f33;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #8899a6;
  margin-top: 60px;
  img {
    max-width: 200px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff;
  }
}
</style>
