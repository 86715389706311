<template>
  <div id="sent-mails">
    <span
      ><strong>Mails Sent: </strong>
      <div class="emails">{{ emails }}</div></span
    >
  </div>
</template>
<script>
const defaults = {
  emails: ['None']
}
export default {
  props: {
    sent_authors: {
      type: Array,
      required: true
    }
  },
  computed: {
    emails() {
      const emails =
        this.sent_authors.length > 0 ? this.sent_authors : defaults.emails
      return emails.join(', ')
    }
  }
}
</script>
<style lang="scss" scoped>
#sent-mails {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0.1em;
  z-index: 100;
  background: #ff2641;
  color: black;
  .emails {
    display: inline-block;
    user-select: all;
  }
}
</style>
