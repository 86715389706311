const props = {
  issue_name: {
    type: String,
    required: true
  },
  issue_release: {
    type: String,
    required: true
  },
  upcoming: {
    type: Boolean,
    required: true
  },
  deadline: {
    type: String,
    required: true
  },
  count: {
    type: Number,
    required: true
  },
  author: {
    type: Object,
    required: true
  },
  scroll: {
    type: Function
  },
  new_author: {
    type: Function
  },
  editor: {
    type: Object,
    required: true
  }
}

export { props }
